<template>
  <div id="subbar">
    <div class="container">
      <component :is="subbarContent"/>
      <div></div> <!-- Prevent flexbox from collapsing -->
      <div class="is-flex is-align-items-center">
        <b-tag v-if="!hasPremium" @click="$router.push('/premium')" type="is-primary" class="mr-4 hoverable">Prueba premium gratis</b-tag>
        <b-tag v-else @click="$router.push('/premium')" type="is-default" class="mr-4 hoverable">{{ premiumType | ucfirst }} usuario</b-tag>
        <b-dropdown v-if="username" ref="dropdown" position="is-bottom-left" aria-role="menu" append-to-body>
          <template #trigger="{ active }">
            <div class="profile-icon hoverable" :class="{ 'is-active': active }">
              <b-icon icon="account" />
            </div>
          </template>
          <div aria-role="menu-item" :focusable="false" custom paddingless>
            <div class="profile-dropdown">
              <div @click="$router.push(`/profile/${username}`)" class="avatar-background hoverable">
                <profile-picture :username="username" />
              </div>
              <h1 @click="$router.push(`/profile/${username}`)" class="title is-3 mt-4 mb-2 hoverable">{{ username }}</h1>
              <b-tag :type="!hasPremium ? 'is-default' : 'is-primary'">{{ premiumType | ucfirst }} usuario</b-tag>
              <div class="columns is-mobile full-width mt-2">
                <div class="column is-4 is-offset-4 has-text-centered">
                  <small class="has-text-weight-bold">Streak</small>
                  <div class="is-clearfix is-flex is-justify-content-center mt-2">
                    <figure class="image is-pulled-left is-32x32">
                      <img src="../assets/icons/fire.svg" />
                    </figure>
                    <span class="title is-4 mb-0 ml-2">{{ streak }}</span>
                  </div>
                </div>
              </div>
              <b-menu class="full-width">
                <b-menu-list>
                  <b-menu-item icon="cog" @click.native="closeDropdown" label="Instellingen" tag="router-link" to="/settings" />
                  <b-menu-item icon="star-outline" @click.native="closeDropdown" label="Abonnement beheren" tag="router-link" to="/premium" />
                  <!-- <b-menu-item icon="logout" @click.native="logout" label="Uitloggen" /> -->
                </b-menu-list>
              </b-menu>
            </div>
          </div>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex"
// import { USER_LOGOUT } from "@/../woordjes.ikleeralles.nl/src/store/User/action.type"
import ProfilePicture from "./ProfilePicture.vue"

export default {
  components: { ProfilePicture },
  methods: {
    // ...mapActions("user", [USER_LOGOUT]),
    changeDarkmode() {
      this.$store.commit(`app/${TOGGLE_DARKMODE}`)
    },
    profile() {
      this.$router.push("/profile/" + this.username)
    },
    async logout() {
      await this[USER_LOGOUT]()

      this.$router.push("/unauthorized")
      this.closeDropdown()
    },
    closeDropdown() {
      this.$refs.dropdown.toggle()
    },
  },
  computed: {
    darkMode() {
      return this.$store.state.app.darkMode
    },
    subbarContent() {
      return this.$route.meta.subbarContent
    },
    ...mapState("user", ["username", "hasPremium", "premiumType", "streak"]),
  },
}
</script>
<style lang="scss" scoped>
#subbar {
  position: relative;
  z-index: 1;
  width: auto;
  height: 60px;
  margin-bottom: 20px;
  background: #f2f1f4;
  border-bottom: 1px solid #e7e7e7;
  .container {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .profile-icon {
      display: flex;
      background-color: #fff;
      border-radius: 50px;
      width: 45px;
      height: 45px;
      align-items: center;
      justify-content: center;
      &.is-active {
        background-color: #1d73f4;
        color: #fff;
      }
    }
  }
  .field {
    margin-bottom: 0 !important;
    margin-left: 5px;
    margin-right: 5px;
  }
}

.profile-dropdown {
  position: relative;
  z-index: 1000;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 350px;
  .avatar-background {
    transition: all 0.2s;
    background: rgb(235, 235, 235);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border-radius: 100%;
    border: 2px inset #fc7234;
    &:hover {
      border: 2px solid #1d73f4 !important;
      background: rgb(206, 210, 236);
    }
  }
  .menu-list {
    li {
      height: 50px;
      ::v-deep a {
        height: 50px;
        display: flex;
        align-items: center;
        border-radius: 10px;
        margin: 5px 0;
        .icon {
          margin-right: 1em;
        }
        &.router-link-exact-active {
          background: #1d73f4;
          color: #fff;
        }
      }
    }
  }
}
</style>
