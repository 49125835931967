import { render, staticRenderFns } from "./Subbar.vue?vue&type=template&id=c078e8aa&scoped=true&"
import script from "./Subbar.vue?vue&type=script&lang=js&"
export * from "./Subbar.vue?vue&type=script&lang=js&"
import style0 from "./Subbar.vue?vue&type=style&index=0&id=c078e8aa&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c078e8aa",
  null
  
)

export default component.exports