<template>
  <nav class="navbar" :class="{ white: isNavActive }">
    <div class="container no-padding">
      <div class="navbar-brand">
        <span class="burger hoverable" data-target="navbarMenuHeroA" :class="{ 'is-active': isNavActive }" @click="toggleNavMenu()">
          <b-icon icon="menu" />
        </span>
      </div>
      <div class="navbar-menu is-marginless" :class="{ 'is-active': isNavActive }">
        <div class="navbar-start has-text-centered">
          <router-link class="navbar-item" to="/" exact="exact" @click.native="closeNavbar()">{{ $t("components.navbar.home") }}</router-link>
          <router-link class="navbar-item" to="/login" exact="exact" @click.native="closeNavbar()">{{ $t("components.navbar.login") }}</router-link>
          <router-link class="navbar-item" to="/aanmelden" exact="exact" @click.native="closeNavbar()">{{ $t("components.navbar.register") }}</router-link>
          <router-link class="navbar-item" to="/contact" exact="exact" @click.native="closeNavbar()">{{ $t("components.navbar.contact") }}</router-link>
          <slot name="navbar-links"></slot>
        </div>
        <!-- <div class="navbar-end logo" v-if="loggedIn == false">
          <img width="60" src="../assets/logo-no-text.svg" alt="logo" />
        </div> -->
      </div>
    </div>
  </nav>
</template>

<script>
import { EventBus } from "@/common/event-bus"
import cookie from "vue-cookies"

export default {
  name: "navbar",
  props: ["environment"],
  data() {
    return {
      darkMode: false,
      isNavActive: false,
      isDropdownActive: false,
    }
  },
  mounted() {
    this.darkMode = !!cookie.get("darkMode")
  },
  methods: {
    toggleNavMenu() {
      this.isNavActive = !this.isNavActive
    },
    async logout() {
      await this.$store.dispatch("user/logout")
      window.location.href = "/"
      this.$buefy.toast.open({
        message: "Je bent succesvol uitgelogd",
        type: "is-success",
      })
    },
    toggleDarkMode() {
      EventBus.$emit("darkMode", this.darkMode)
    },
    closeNavbar() {
      this.isNavActive = false
    },
    redirect(env) {
      window.location.href = `https://${env}.ikleeralles.nl`
    },
  },
  computed: {
    loggedIn() {
      this.$forceUpdate()
      return this.$store.state["user"].loggedIn
    },
    username() {
      return this.$store.state.user.username.toLowerCase()
    },
    hasPremium() {
      return this.$store.state.user.hasPremium
    },
    textBlack() {
      return this.$route.meta.textBlack
    },
    isBlue() {
      return this.$route.meta.isBlue
    },
  },
  watch: {
    $route() {
      this.isNavActive = false
    },
  },
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 70px;
  background: #1d73f4;
  &.white {
    background: #fff;
    .navbar-brand {
      color: #000;
    }
  }
  .container {
    padding: 0;
  }
  .logo-text {
    display: none;
  }
  .navbar-brand {
    display: flex;
    align-items: center;
    padding-left: 20px;
    color: #fff;
    @media only screen and (min-width: 1024px) {
      display: none;
    }
  }
  .navbar-start {
    margin: 0 auto;
    .navbar-item {
      height: 100%;
      min-width: 80px;
      text-align: center;
      padding: 0 20px;
      color: #fff;
      font-weight: 600;
      font-size: 0.9rem;
      &:hover,
      &:active,
      &:focus {
        color: #ccc;
        background: transparent;
      }
    }
  }
  .navbar-menu.is-active {
    box-shadow: none;
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(15px);
    padding-bottom: 30px;
    .navbar-item {
      color: #000;
      margin-top: 20px;
      text-align: left;
    }
  }
}
.environment {
  .fas {
    margin-right: 10px;
  }
}
a.dropdown-item,
.has-link a {
  text-align: left !important;
}
</style>
