import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

import { posthog, PostHog } from 'posthog-js'

import * as mutation from './mutation.type'
import { USER_INIT, USER_LOGIN, USER_LOGOUT, USER_REGISTER, USER_UPDATE, USER_REQUEST_PASSWORD } from "./action.type"


Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    init: false,
    loggedIn: false,
    username: null,
    role: "student",
    hasPremium: false,
    premiumType: "standaard",
    streak: 0,
    year: 1,
    level: "vwo",
    folders: [],
    groups: [],
    autoTranslate: true,
    lastBookLanguage: null,
    lastBookFilters: null,
    lastSearchFilters: null,
    selectedInputIndex: null
  },
  mutations: {
    [mutation.SET_INIT](state, data) {
      state.init = data
    },
    [mutation.SET_LOGGEDIN](state, data) {
      state.loggedIn = data
    },
    [mutation.SET_USERNAME](state, data) {
      state.username = data
    },
    [mutation.SET_PREMIUM](state, data) {
      state.hasPremium = data
    },
    [mutation.SET_INITIALDATA](state, data) {
      state.username = data.username
      state.hasPremium = data.hasPremium
      state.oldPremium = data.oldPremium
      state.year = data.year
      state.level = data.level
      state.streak = data.streak
    },
    [mutation.SET_PREMIUMTYPE](state, data) {
      state.premiumType = data
    },
    [mutation.SET_OLDPREMIUM](state, data) {
      state.oldPremium = data
    },
    [mutation.SET_EDUCATION](state, data) {
      state.year = data.year
      state.level = data.level
    },
    [mutation.SET_FOLDERS](state, data) {
      state.folders = data
    },
    [mutation.SET_GROUPS](state, data) {
      state.groups = data
    },
    [mutation.SET_AUTOTRANSLATE](state, data) {
      state.autoTranslate = data
    },
    [mutation.SET_LASTBOOKLANGUAGE](state, data) {
      state.lastBookLanguage = data
    },
    [mutation.SET_LASTBOOKFILTERS](state, data) {
      state.lastBookFilters = data
    },
    [mutation.SET_LASTSEARCHFILTERS](state, data) {
      state.lastSearchFilters = data
    },
    [mutation.SET_SELECTEDINPUTINDEX](state, data) {
      state.selectedInputIndex = data
    },
    [mutation.SET_EDNEEDSUPDATE](state, data) {
      state.updatedEd = data
    },
    [mutation.SET_STREAK](state, data) {
      state.streak = data
    }
  },
  actions: {
    async [USER_INIT]({ state, commit, dispatch }) {
      dispatch("refreshFolders")
      dispatch("refreshGroups")
      dispatch("getPremiumType")

      commit(mutation.SET_INIT, true)
    },
    async [USER_UPDATE]({ state, commit, dispatch }, payload) {
      let response = await Vue.prototype.$http.get("/user/me")
      if (response.status == 200) {
        commit(mutation.SET_LOGGEDIN, true)
        commit(mutation.SET_OLDPREMIUM, response.data.oldPremium)
        commit(mutation.SET_PREMIUM, response.data.hasPremium)
        commit(mutation.SET_USERNAME, response.data.username)
        commit(mutation.SET_EDUCATION, { year: response.data.year, level: response.data.level })
        commit(mutation.SET_EDNEEDSUPDATE, response.data.updatedEd)
        commit(mutation.SET_STREAK, response.data.streak)
        
        Vue.prototype.$posthog.identify(response.data.username, { username: response.data.username, year: response.data.year, level: response.data.level })

        if(!state.init && payload.requiresAuth) await dispatch(USER_INIT)
      }
    },
    async [USER_LOGIN](context, payload) {
      const response = await Vue.prototype.$http.post("auth/login", payload)
      if (response.status === 401) return false
      
      context.commit(mutation.SET_LOGGEDIN, true)
      context.commit(mutation.SET_USERNAME, payload.username)

      return true
    },
    async [USER_REGISTER](context, payload) {
      const response = await Vue.prototype.$http.post("user", payload)
      if (response.status == 400) {
        return response
      }
      context.commit(mutation.SET_LOGGEDIN, true)
      context.commit(mutation.SET_USERNAME, payload.username)

      return response
    },
    async [USER_REQUEST_PASSWORD](context, payload) {
      return await Vue.prototype.$http.post("user/requestpassword", { username: payload })
    },
    async [USER_LOGOUT](context) {
      await Vue.prototype.$http.get("/user/logout")

      context.commit(mutation.SET_LOGGEDIN, false)
      context.commit(mutation.SET_USERNAME, "")

      axios.defaults.headers.common = {
        Authorization: ""
      }
    },
    async getPremiumType(context) {
      const { data } = await Vue.prototype.$http.get("/user/premium")

      if (data.subscriptionType) context.commit('setPremiumType', data.subscriptionType)
    },
    async refreshFolders(context) {
      const response = await Vue.prototype.$http.get("folder/me")
      context.commit(mutation.SET_FOLDERS, response.data)
    },
    async refreshGroups(context) {
      const response = await Vue.prototype.$http.get("group/me")
      context.commit(mutation.SET_GROUPS, response.data)
    }
  }
}
