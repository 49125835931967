export default {
    components: {
        navbar: {
            home: 'Inicio',
            login: 'Iniciar sesión',
            register: 'Registrarse',
            contact: 'Contacto',
        },
        chat: {
            welcomeMessage: "Hola, puedo ayudarte con el repaso. Sube fotos de las páginas de tu libro para comenzar.",
            phone: "Teléfono",
            computer: "Computadora",
            upload: "Subir",
            qrInstruction: "Escanee el código QR para cargar la(s) imagen(es) desde su teléfono. Una vez recibido, ¡el cuestionario comienza aquí!",
            computerInstruction: "Cargue las imágenes que desea probar a continuación",
            delete: "Borrar",
            badQuestion: "Esta es una mala pregunta",
            goodQuestion: "Esta es una buena pregunta",
            typeAnswer: "Escriba su respuesta aquí",
            endConversation: "Detener llamada",
            uploadText: "Haga clic aquí para cargar archivos",
            userLabel: "usuario",
            send: "envía",
            newTest: "Nueva prueba",
            lostConnection: "Conexión perdida. Reconectando...",
            nps: {
                question: "¿Qué posibilidades hay de que recomiendes StudyBuddy a tus amigos?",
            },
            premiumModal: {
                title: "Opción premium",
                content: "<p>Para poder pagar StudyBuddy, se ha convertido en parte de la suscripción Premium. <br /><br />Pruébalo ahora durante <strong>gratis</strong> 7 días gratis!</p>",
                buttonTry: "Intentar"
            },
            questionTypes: {
                open: "Pregunta abierta",
                multiple_choice: "Pregunta de opción múltiple",
                insight: "Pregunta de comprensión",
            },
        },
    },
    messages: {
        loginSuccess: "Has iniciado sesión correctamente",
    },
    home: {
        title: 'Aprende más rápido y de manera más divertida para obtener mejores calificaciones',
        subtitle: 'Con este programa de repaso, aprenderás de manera rápida y divertida la teoría que debes conocer. ¡Con StudyBuddy, repasar ya no es aburrido!',
        login: 'Iniciar sesión',
        register: 'Registrarse',
        tryOut: 'Probar ahora',
        section1_title: "El 91% de los estudiantes obtienen calificaciones más altas con StudyBuddy",
        section1_subtitle: "¡Empieza a aprender gratis ahora!",
        section2_title: "Rellenar listas de palabras.",
        section2_subtitle: "Más rápido que nunca",
        section2_p1: "También en StudyBuddy puedes crear listas de palabras simplemente llenándolas con las palabras.",
        section2_p2_title: "Crear lista de palabras:",
        section2_p2: "Puedes crear fácilmente una lista de palabras llenando las palabras y sus traducciones. Muchas palabras se traducen automáticamente, lo que hace que el proceso sea hasta dos veces más rápido de lo que estás acostumbrado.",
        section2_p3_title: "Idiomas:",
        section2_p3: "Puedes elegir el tema de cada columna. Esto significa que puedes practicar en todos los idiomas. ¡Después de llenarlas, las palabras incluso se traducen automáticamente!",
        section3_title: "Repasar.",
        section3_subtitle: "Como estás acostumbrado",
        section3_p1: "Por supuesto, también es posible repasar palabras. Con diferentes configuraciones, puedes personalizar la revisión como desees.",
        section3_p2_title: "Escribir o pensar:",
        section3_p2: "Durante la revisión, puedes elegir escribir la respuesta correcta o trabajar de la manera tradicional en papel.",
        section3_p3_title: "Repasar a fondo:",
        section3_p3: "Puedes repasar hasta que respondas correctamente a todas las preguntas.",
        section3_p4_title: "Resultados:",
        section3_p4: "Después de la revisión, recibirás resultados detallados. Por ejemplo, puedes ver cuántas veces te equivocaste en cada palabra. ¡Incluso puedes crear una nueva lista con tus errores!",
        section4_title: "Lo que dicen los demás...",
        section4_review1_name: "Saskia",
        section4_review1: "StudyBuddy es una plataforma de aprendizaje fácil con la que estoy muy satisfecha.",
        section4_review2_name: "Pieter",
        section4_review2: "El equipo de StudyBuddy responde muy rápidamente a través de Discord si me encuentro con algún problema.",
        section4_review3_name: "Niek",
        section4_review3: "Es un programa sencillo que definitivamente puedo recomendar. ¡Salvó mi escuela secundaria!",
        cta_title: "Comenzar",
        cta_subtitle: "Crea una cuenta gratuita de inmediato para comenzar a aprender",
        cta_button: "Registrarse"
    },
    contact: {
        title: "Contacto",
        description: "¿Tienes un problema o una pregunta? Ponte en contacto con StudyBuddy",
        emailLabel: "Correo electrónico",
        messageLabel: "Mensaje",
        sendButton: "Enviar"
    },
    login: {
        title: "Iniciar sesión",
        usernameOrEmail: "Nombre de usuario o correo electrónico",
        password: "Contraseña",
        forgotPassword: "¿Olvidaste tu contraseña?",
        noAccount: "No tengo una cuenta",
        loginButton: "Iniciar sesión"
    },
    alternative: {
        title: '¿No te divierte Quizlet?',
        subtitle: '¿Buscas una alternativa a Quizlet? ¡No busques más! StudyBuddy es el programa de repaso más divertido para aprender palabras o conceptos.',
        start_button: 'Empezar',
        section1_title: 'Aprender palabras.',
        section1_subtitle: 'Ya no es aburrido',
        section1_p1: 'Aprender palabras lleva mucho tiempo y casi siempre es aburrido. Es muy importante en la escuela aprender un idioma. También para otras materias y una futura educación a veces tendrás que aprender conceptos.',
        section1_p2: 'Entendemos que quieres aprender tus palabras rápidamente. Por eso hemos desarrollado un método eficiente para empezar de inmediato. ¡Nos aseguramos de que el trabajo de memorización sea lo más divertido posible!',
        section2_title: 'Comparar',
        section2_p1: 'A continuación comparamos los aspectos que, en nuestra opinión, son los más importantes para aprender palabras de manera divertida y eficiente.',
        unlimited_quizzes: 'Repasar ilimitadamente gratis',
        collect_medals: 'Coleccionar medallas',
        color_words: 'Colorear palabras',
        merge_lists: 'Combinar listas',
        dark_mode: 'Modo oscuro',
        auto_translate: 'Traducción automática de palabras',
        ready_title: '¿Listo para empezar?',
        register_button: 'Registrarse'
    },
    register: {
        title: "Registrarse",
        social_buttons: {
            google: "Google",
            discord: "Discord"
        },
        scholier_tab: "Estudiante",
        teacher_tab: "Docente",
        username: "Nombre de usuario",
        password: "Contraseña",
        email: "Correo electrónico",
        agree_to: "Acepto los",
        terms: "Términos y condiciones",
        create_account: "Crear cuenta",
        already_have_account: "Ya tengo una cuenta",
        select_year: "Seleccione su año",
        select_education: "Seleccione su educación",
        other: "Otro",
        high_field: {
            label: {
                year: "Año de estudio",
                education: "Educación"
            }
        },
        year: "Año escolar",
        education_level: "Nivel de Educación",
        student_tab: "Estudiante",
    },
    forgot_password: {
        instructions: "¿Olvidaste tu contraseña? ¡No hay problema! Ingresa tu nombre de usuario o dirección de correo electrónico a continuación y recibirás un enlace para restablecer tu contraseña.",
        username_or_email: "Nombre de usuario o dirección de correo electrónico",
        request_button: "Solicitar"
    },
    reset_password: {
        new_password: "Nueva contraseña",
        change_button: "Cambiar"
    },
    sce: {
        home: "Inicio",
        login_button: "Iniciar sesión",
        create_account: "Crear cuenta"
    },
    studybuddy: {
        title: "El nuevo repaso con ChatGPT",
        subtitle: "Para repasar, debes pedir a tus padres o amigos que lo hagan desde tu libro. También puedes crear preguntas y respuestas y ponerlas en un programa de repaso. Ambos métodos toman mucho tiempo. ¡Con ChatGPT e StudyBuddy, todo esto se puede hacer automáticamente!",
        try_now: "Probar ahora",
        section1: {
            title: "Repasar todo.",
            subtitle: "De una foto",
            content: "¿Tienes una prueba de historia pronto o necesitas estudiar biología? Probablemente tengas que leer, recordar y comprender muchos textos. No siempre es fácil, especialmente si no estás seguro de si ya conoces el material. En StudyBuddy, ahora puedes tomar una foto de un texto y repasar con preguntas que el sistema crea automáticamente para ti. ¡Así podrás aprender rápidamente cualquier tema!"
        },
        section2: {
            small_title: "TEORÍA",
            title: "Prueba de historia.",
            subtitle: "La Segunda Guerra Mundial",
            content: "Tu próxima prueba es sobre la Segunda Guerra Mundial. Quieres ser evaluado rápidamente para ver si has estudiado lo suficiente. Tomas una foto como la de al lado y la subes a StudyBuddy. ¡Puedes empezar en 10 segundos!"
        },
        section3: {
            small_title: "EJERCICIOS",
            title: "Matemáticas.",
            subtitle: "Practicar más",
            content: "Necesitas comprender un tema específico de matemáticas y te gustaría practicar más. No quieres perder mucho tiempo creando o buscando preguntas. Tomas una foto como la de al lado y la subes a StudyBuddy. El sistema generará preguntas similares para que puedas practicar más."
        },
        demo: {
            small_title: "DEMO",
            title: "Prueba StudyBuddy aquí"
        },
        ready: {
            title: "¿Listo para seguir probando?",
            register: "Registrarse"
        }
    },
    error_page: {
        message: "Parece que algo salió mal al cargar la página.",
        reload_button: "Recargar"
    },
    topography: {
        title: "Practicar topografía",
        subtitle: "Con StudyBuddy, aprende de memoria los mapas de los Países Bajos y de todo el mundo. ¿Necesitas conocer las provincias y capitales de los Países Bajos? ¿O tienes que aprender Europa de memoria? ¡Puedes practicar topografía gratis aquí!",
        get_started: "Empezar",
        section1: {
            title: "Topo Países Bajos.",
            subtitle: "Bueno saber",
            content: "StudyBuddy es el mejor sitio para repasar topografía. Tenemos muchos mapas diferentes disponibles para ti. Por ejemplo, las provincias y capitales de los Países Bajos. \n\nTambién puedes crear tus propios mapas para repasarexactamente lo que tú quieras!"
        },
        ready: {
            title: "¿Listo para empezar?",
            register: "Registrarse"
        }
    },
    learnWords: {
        words: {
            title: "Aprender palabras",
            subtitle: "StudyBuddy es el programa de repaso para aprender palabras en línea. Con nosotros, puedes completar rápidamente tu lista de palabras y aprender tanto como sea posible.",
            getStarted: "Comenzar"
        },
        latestOptions: {
            title: "Las últimas opciones.",
            subtitle: "Para aprender aún mejor",
            description: "Ofrecemos muchas opciones de repaso diferentes y recientemente hemos desarrollado una nueva opción: <strong>Aleatorio</strong> <br /><br /> Con esta opción de repaso, recibirás preguntas aleatorias: <i>opción múltiple</i>, <i>pensamiento</i> o <i>escribiendo</i>. Además, también se mostrarán oraciones de ejemplo en las que debes completar la palabra faltante."
        },
        exampleSentences: {
            title: "Frases de ejemplo.",
            subtitle: "¿Qué palabra falta?",
            description: "En StudyBuddy puedes aprender palabras fácilmente al ponerte a prueba. Puedes usar las configuraciones a las que estás acostumbrado. ¿Prefieres escribir la respuesta o repasar en tus pensamientos? Ambos son posibles, junto con muchas más configuraciones avanzadas de repaso. <br /><br /> Sin tus padres, ahora puedes aprender en línea de manera más eficiente y prepararte para tu próxima prueba o examen."
        },
        bestResult: {
            title: "El mejor resultado.",
            subtitle: "En el menor tiempo",
            description: "En StudyBuddy puedes aprender palabras fácilmente al ponerte a prueba. Puedes usar las configuraciones a las que estás acostumbrado. ¿Prefieres escribir la respuesta o repasar en tus pensamientos? Ambos son posibles, junto con muchas más configuraciones avanzadas de repaso. <br /><br /> Sin tus padres, ahora puedes aprender en línea de manera más eficiente y prepararte para tu próxima prueba o examen."
        },
        readyToStart: {
            title: "¿Listo para empezar?",
            register: "Registrarse"
        }
    },
    wrtsAlternative: {
        title: "StudyBuddy es la alternativa a Wrts.nl",
        subtitle: "No hay escapatoria de que a veces tienes que aprender palabras o conceptos para una materia en la escuela secundaria. Incluso en la escuela primaria y durante los estudios universitarios, a menudo hay que aprender palabras. Es inteligente ponerse a prueba en línea con un programa de repaso como StudyBuddy. <br /><br /> Intentamos establecer una plataforma moderna con opciones innovadoras en StudyBuddy. A continuación, comparamos StudyBuddy con Wrts.nl.",
        getStarted: "Empezar",
        comparisonTitle: "Comparar",
        comparisonSubtitle: "A continuación, comparamos los aspectos que creemos que son más importantes en un programa de repaso.",
        tableHeaders: {
            feature: "",
            ikleeralles: "StudyBuddy",
            wrts: "Wrts.nl"
        },
        tableRows: {
            monthlyPrice: "Precio por mes",
            freeMentalPractice: "Repaso en la mente gratis",
            freeForTeachers: "Gratis para profesores",
            colorWords: "Colorear palabras",
            mergeLists: "Fusionar listas",
            darkMode: "Modo oscuro",
            autoTranslateWords: "Traducción automática de palabras",
            geographyPractice: "Repaso de geografía"
        }
    },
    scanOnPhone: {
        title: "Toma una foto o sube un archivo",
        clickHere: "Haga clic aquí para tomar una foto o cargar un archivo",
        takesTime: "Esto suele tardar unos 10 segundos...",
        uploadButton: "Subir"
    }

    // ... Add other Spanish translations
}