import store from '../../woordjes.ikleeralles.nl/src/store'
import { lazyLoadView } from './lazyloadview'

export default [
  //ROUTES WHEN LOGGED OUT
  {
    path: '/',
    name: 'home',
    component: () => lazyLoadView(import('../pages/Landing/Home.vue')),
    meta: {
      title: 'Nuevo programa de audición',
      showFooter: true,
      hideSubbar: true,
      navbarTopShow: true,
      hideSidebar: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => lazyLoadView(import('../pages/Landing/Login.vue')),
    meta: {
      title: 'Acceso',
      showFooter: false,
      hideSubbar: true,
      navbarTopShow: true,
      hideSidebar: true
    }
  },
  {
    path: '/requestpassword',
    component: () => lazyLoadView(import('../pages/Landing/RequestPassword.vue')),
    meta: {
      title: 'Olvidaste tu contraseña',
      requiresAuth: false,
      navbarTopShow: true,
      hideSubbar: true,
      hideSidebar: true
    }
  },
  {
    path: '/resetpassword/:code',
    props: true,
    component: () => lazyLoadView(import('../pages/Landing/ResetPassword.vue')),
    meta: {
      title: 'Wachtwoord resetten',
      requiresAuth: false,
      navbarTopShow: true,
      hideSubbar: true,
      hideSidebar: true
    }
  },
  {
    path: '/aanmelden',
    component: () => lazyLoadView(import('../pages/Landing/Register.vue')),
    props: { hideEducation: true },
    meta: {
      title: 'Registro',
      requiresAuth: false,
      showFooter: false,
      navbarTopShow: true,
      hideSubbar: true,
      hideSidebar: true
    }
  },
  {
    path: '/upload/:shortId/:textType',
    props: true,
    component: () => lazyLoadView(import('../pages/Landing/Scan/UploadFromPhone.vue')),
    meta: {
      title: 'Sube una foto',
      requiresAuth: false,
      showFooter: false,
      navbarTopShow: true,
      isBlue: false,
      hideSubbar: true,
    }
  },
  {
    path: '/contact',
    component: () => lazyLoadView(import('../pages/Contact.vue')),
    meta: {
      title: 'Contact',
      requiresAuth: false,
      navbarTopShow: true,
    }
  },
  //ROUTES WHEN LOGGED IN
  {
    path: '/premium',
    component: () => lazyLoadView(import('../pages/Premium/Premium.vue')),
    meta: {
      title: 'Premium',
      requiresAuth: true
    }
  },
  {
    path: '/studybuddy',
    component: () => lazyLoadView(import('../pages/Chat.vue')),
    meta: {
      title: 'StudyBuddy',
      requiresAuth: true,
      subbarContent: () => import('../../woordjes.ikleeralles.nl/src/pages/Chat/components/SubbarContent.vue'),
    },
  },
  {
    path: '/scan/:shortId',
    props: true,
    component: () => lazyLoadView(import('../pages/Scan/ScanOnPhone.vue')),
    meta: {
      title: 'StudyBuddy',
      requiresAuth: false,
      hideSidebar: true,
    },
  },
  {
    path: '/settings',
    props: true,
    component: () => lazyLoadView(import('../pages/Settings/Settings.vue')),
    name: 'settings',
    meta: {
      title: 'Instellingen',
      requiresAuth: true
    },
    children: [
      {
        path: '',
        alias: 'account',
        meta: {
          title: 'Account instellingen',
        },
        component: () => lazyLoadView(import('../pages/Settings/Account.vue'))
      },
      {
        name: 'settings-education',
        path: 'education',
        meta: {
          title: 'Opleiding instellingen',
        },
        component: () => lazyLoadView(import('../pages/Settings/Education.vue'))
      },
      {
        name: 'settings-theme',
        path: 'theme',
        meta: {
          title: 'Website instellingen',
        },
        component: () => lazyLoadView(import('../pages/Settings/Theme.vue'))
      }
    ]
  },
  {
    name: 'unauthorized',
    path: '/unauthorized',
    props: true,
    meta: {
      title: "Unauthorized",
      hideSubbar: true,
    },
    component: () => lazyLoadView(import('../pages/Unauthorized.vue')),
  },
  {
    path: '/404',
    name: '404',
    component: require('../pages/404.vue').default,
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
    meta: {
      title: "404 Not found",
      hideSubbar: true,
    }
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    redirect: '/404'
  }
]
