import Vue from 'vue'
import router from './router'
import store from '../woordjes.ikleeralles.nl/src/store'
import App from './App.vue'
import Buefy from 'buefy'
import VueSocketIO from 'vue-socket.io'
import VueCookies from 'vue-cookies'
import './vee-validate'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Clipboard from 'v-clipboard'

import VueGtag from "vue-gtag"
import VueConfetti from 'vue-confetti'
import CountryFlag from 'vue-country-flag'
import VueWaypoint from 'vue-waypoint'
import posthog from "posthog-js"

// i18n
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

import nlMessages from './lib/i18n/nl'
import esMessages from './lib/i18n/es-EC'
import enMessages from './lib/i18n/en'

const messages = {
  nl: nlMessages,
  es: esMessages,
  en: enMessages,
}

const i18n = new VueI18n({
  legacy: true,
  locale: "es",
  fallbackLocale: "en",
  globalInjection: true,
  messages,
})

//Install lodash and add to vue prototype
import lodash from 'lodash'
Vue.prototype._ = _

import ApiService from './common/api.service'
import { loadingMixin } from "./common/mixins"
import './common/directives'
import '../woordjes.ikleeralles.nl/src/common/filters'

Vue.config.productionTip = process.env.NODE_ENV === 'production'

Vue.use(VueConfetti)
Vue.use(Buefy)
Vue.use(VueCookies)
Vue.use(VueGtag, { config: { id: "G-C1YTQ831S3" } }, router)
Vue.use(Clipboard)
Vue.component('country-flag', CountryFlag)
Vue.use(VueWaypoint)
Vue.use(loadingMixin)

Vue.use(
  new VueSocketIO({
    debug: false,
    connection: process.env.VUE_APP_API,
    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_"
    },
    withCredentials: false,
  })
)

posthog.init('phc_Px2GzDt6gz0Hp3EU8BsrogVM1HHxw0uLn9hgkvfBrUT', {
  api_host: 'https://eu.posthog.com', session_recording: {
    maskAllInputs: false
  }
})
Object.defineProperty(Vue.prototype, '$posthog', { value: posthog })

Vue.$cookies.config("7d", null, process.env.VUE_APP_COOKIE_DOMAIN)

Vue.prototype.$http = new ApiService()

new Vue({
  router: router,
  store: store,
  i18n: i18n,
  render: (h) => h(App),
  mounted() {

    AOS.init({
      duration: 600,
      once: true,
    })
  }
}).$mount('#app')
